var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"8"}},[_c('v-card',{attrs:{"tile":"","elevation":"2"}},[_c('v-card-title',{staticClass:"py-1"},[_vm._v(" "+_vm._s(_vm.$t('registrations'))+" ")]),_c('v-data-table',{staticClass:"elevation-2",attrs:{"headers":_vm.headers,"items":_vm.customerRegistrationRowsList,"items-per-page":10,"sort-by":'utcRegistrationDate',"sort-desc":"","footer-props":{
                        itemsPerPageOptions: [10,20,50, -1],
                        'items-per-page-text': _vm.$t('entranceList-nrOfItems')
                    }},scopedSlots:_vm._u([{key:"item.registrationNumber",fn:function(ref){
                    var item = ref.item;
return [_c('plate',{attrs:{"registration-number":item.registrationNumber,"registration-type":item.registrationType}})]}},{key:"item.utcRegistrationDate",fn:function(ref){
                    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("luxon")(item.utcRegistrationDate,{ input: "formatutc", output: "formatlocal" }))+" ")]}},{key:"item.orderItemPriceInMinorUnit",fn:function(ref){
                    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.globalConvertMinorCurrencyToLocal(item.orderItemPriceInMinorUnit))+" ")]}},{key:"item.orderStatus",fn:function(ref){
                    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$t('paymentStatus-'+item.orderStatus))+" ")]}},{key:"item.registrationHasOrder",fn:function(ref){
                    var item = ref.item;
return [(item.registrationHasOrder)?_c('v-btn',{attrs:{"text":""},on:{"click":function($event){return _vm.getOrderPdf(item.id)}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-file-pdf ")])],1):_vm._e()]}}])})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }